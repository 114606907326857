<template>
  <v-card>
    <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        Cadastrar uma cotação
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
    <v-container class="form">
      <v-form>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="pb-0 pt-0">
              <v-card-text
                class="pt-0 pl-0 pb-1 pr-0"
              >
              Bloquear palpite
              </v-card-text>
              <v-text-field
                single-line
                outlined
                dense
                v-model="palpite"
                :error-messages="palpiteError"
                @input="$v.palpite.$touch()"
                @blur="$v.palpite.$touch()"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            type="submit"
            :color="variables.colorPrimary"
            @click.prevent="submit"
            class="br-btn"
            dark
          >Adicionar</v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
// import axios from 'axios'
// import { required, email, requiredIf } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'

// import config from '@/core/config'
import variables from '@/assets/styles/helpers/_variables.scss'
import validacao from './validacao'


const validatePalpite = modalidade => palpite => {
  let palpiteLimpo = palpite.replace(/[^0-9]/gi, "")
  const configModaliade = modalidade ? modalidade[0].config : []
  if (palpiteLimpo.length === 0) return true

  if (palpiteLimpo.length > configModaliade.QT_MAX || palpiteLimpo.length < configModaliade.QT_MIN ) {
    return false
  } 
  return true
}

export default {
  name: "CadastroContato",
  directives: {mask},
  mixins: [validationMixin],
  components: {
    // searchUfCity: () => import('@/views/components/searchUfCity')
  },

  props: {
    configModaliade: {
      type: Array,
      default: () => []
    },


    activeModal: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isAddress: false,
    isDocument: false,
    isMemo: false,
    cepValidate: false,
    loading: false,
    palpite: ''
  }),

  watch: {
    'activeModal' (val) {
      if (!val) this.clearForm()
    }
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('cotacaoDinamica', ['cotacao']),
    ...validacao,
    variables: () => variables,

    palpiteError() {
      const errors = []
      if (!this.$v.palpite.$dirty) return errors
      !this.$v.palpite.validatePalpite && errors.push('Palpite Invalido!')
      return errors
    }
    
  },

  methods: {
    ...mapActions('cotacaoDinamica', ['registerData', 'clearData']),

    submit () { 
      if (this.$v.$invalid) { 
        this.$v.$touch()
        return false 
      }

      this.$emit('registrarCotacao', this.palpite)
    

    },

    closeModal () {
      this.$emit('closeModal', false)
    },

    clearForm () {
      this.$v.data.$reset()
      this.clearData()
    }
  },
  
  validations () {
    return  {
      palpite: { validatePalpite: validatePalpite(this.configModaliade) }
    }
  }
}
</script>

<style lang="scss" scoped>
  .form::v-deep {
   .modal-cadastro-form-col-imput {
      padding-top: 0;
      padding-bottom: 0;
    }
   
   .v-text-field.v-text-field--enclosed .v-text-field__details {
     padding: 0
   }
  }

  .list {
    padding-left: 0;
    &-item {
      display: inline-block;
      list-style: none;
      cursor: pointer;
      color: #5076ff;

      &.active {
        color: #9797ad;
      }
    }
  }
</style>